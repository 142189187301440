<template>
    <v-container>
        <v-row>
            <v-col align="center">
                <v-btn
                    class="altaRouter"
                    width="170px"
                    style="padding: 25px"
                    @click="altaRouter"
                    color="primary"
                >Alta</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col align="center">
                <v-btn
                    style="padding: 25px"
                    width="170px"
                    @click="historico"
                    color="primary"
                >Histórico</v-btn>
            </v-col>
        </v-row>
    </v-container>
</template>
<script>
export default {
    data () {
        return {}
    },
    methods: {
        altaRouter () {
            this.$router.push('/dashboard')
        },
        historico () {
            this.$router.push('/historico')
        },
        checkIfLoggedIn () {
            if (this.$store.getters.isLoggedIn === false) {
                this.$router.push('/')
            }
        }
    },
    beforeMount () {
        this.checkIfLoggedIn()
    }
}
</script>
